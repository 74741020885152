import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  top: 10%;
  transform: translateY(10%);
  left: auto;
  text-align: center;
`;
