import React from 'react';
import styled from 'styled-components';
import { MdSentimentDissatisfied, MdClose, MdError } from 'react-icons/md';
import { colors } from '~/assets/styles/variables';

const { gray } = colors;

const defaultConfig = (props: any) => ({
  size: props.size ? props.size : 22,
});

interface IconInterface {
  color?: string
  padding?: string
  border?: string
  iconHeight?: string
  iconWidth?: string
  margin?: string
  borderRadius?: string
  backgroundColor?: string
}

export const CloseIcon = styled(MdClose).attrs(defaultConfig)`
  color: ${({ color }: IconInterface) => (color || gray)};
`

export const AlertIcon = styled(MdError).attrs(defaultConfig)`
  color: ${({ color }: IconInterface) => (color || gray)};
  margin: ${({ margin }: IconInterface) => (margin || 'auto')};
  height: ${({ iconHeight }: IconInterface) => (iconHeight)};
  width: ${({ iconWidth }: IconInterface) => (iconWidth)};
`

export const SentimentDissatisfiedIcon = styled(MdSentimentDissatisfied).attrs(
  defaultConfig
)`
  color: ${({ color }: IconInterface) => color || gray};
`;

const Icon = (params: JSX.Element) => {
  const icon: any = {
    SentimentDissatisfiedIcon: <SentimentDissatisfiedIcon {...params} />,
    Close: <CloseIcon {...params} />,
    Alert: <AlertIcon {...params} />,
  };
  return icon[params.type] || null;
};

export default Icon;
