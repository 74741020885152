import axios from 'axios'

const baseURL = "https://backend-prod.acertapromotora.com.br/prize-hub"

const api = axios.create({
    baseURL,
})

api.interceptors.request.use(async (config) => {
    if(config.headers) {
        config.headers['X-Api-Key'] = process.env.REACT_APP_API_KEY || ''
    }

    return config
})

export default api;