import { Routes, Route } from 'react-router-dom';

import Roulette from '~/pages/Roulette';

const Router = () => {
  return (
    <Routes>
      <Route path="/:campaignSlug/:contractNumber" element={<Roulette />}></Route>
    </Routes>
  );
};

export default Router;
