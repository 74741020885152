import loaderImage from "~/assets/lottie/floating-gift.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loaderImage,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

export default defaultOptions;
