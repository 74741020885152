interface ColorsInterface {
  lightBlue: string
  blue: string
  cyan: string
  veryDarkGray: string
  darkBlue: string
  darkGray: string
  darkPurple: string
  green: string
  lightGreen: string
  gray: string
  lightGray: string
  veryLightGray: string
  orange: string
  darkOrange: string
  veryWeakOpacityOrange: string
  veryWeakOpacityYellow: string
  veryWeakOpacityRed: string
  veryWeakOpacityBlue: string
  veryWeakOpacityGreen: string
  purple: string
  shadow: string
  red: string
  darkRed: string
  veryDarkRed: string
  white: string
  snow: string
  ligthYellow: string
  faintGreen: string
  darkGreen: string
  veryDarkGreen: string
  faintGray: string
  veryFaintGray: string
  weakBlack: string
  weakGray: string
  veryWeakGray: string
  limeGreen: string
  veryLightBlue: string
  black: string
  lightGrayOpacity: string
  darkGrayOpacity: string
  blackOpacity: string
}

interface ZIndexInterface {
  firstLayer: number
  secondLayer: number
  thirdLayer: number
  fourthLayer: number
  fifthLayer: number
}

export const colors: ColorsInterface = {
  veryLightBlue: '#99c5df',
  lightBlue: '#6b9cff',
  blue: '#204D74',
  cyan: '#55D8FE',
  darkBlue: '#1D4162',
  darkGray: '#A4AFB7',
  darkPurple: '#43425D',
  green: '#5FE3A1',
  faintGray: '#d1d1d1',
  veryFaintGray: '#ebebeb',
  darkGreen: '#58b423',
  veryDarkGreen: '#22440D',
  lightGreen: '#4AD991',
  limeGreen: '#5ad800',
  gray: '#CED8DE',
  lightGray: '#EEF1F4',
  veryDarkGray: '#4D4F5C',
  veryLightGray: '#F5F6FA',
  orange: '#F79028',
  darkOrange: '#4F2903',
  veryWeakOpacityOrange: 'rgba(247, 144, 40, 0.08)',
  veryWeakOpacityYellow: 'rgba(255, 255, 0, 0.2)',
  veryWeakOpacityRed: 'rgba(255, 50, 0, 0.08)',
  veryWeakOpacityBlue: 'rgba(34, 77, 116, 0.08)',
  veryWeakOpacityGreen: 'rgba(95, 227, 161, 0.08)',
  lightGrayOpacity: 'rgba(0, 0, 0, 0.07)',
  darkGrayOpacity: 'rgba(164, 175, 183, 0.56)',
  purple: '#412CB4',
  shadow: '#0000001A',
  red: '#FF6565',
  white: '#FFFFFF',
  snow: '#f1f1f3',
  ligthYellow: '#FFCA83',
  darkRed: '#c14949',
  veryDarkRed: '#d52020',
  weakBlack: '#4d4f5c',
  weakGray: '#666666',
  veryWeakGray: '#CED8DE',
  faintGreen: '#28a745',
  black: '#000',
  blackOpacity: 'rgba(0, 0, 0, 0.5)',
}

export const zIndex: ZIndexInterface = {
  firstLayer: 9,
  secondLayer: 99,
  thirdLayer: 999,
  fourthLayer: 9999,
  fifthLayer: 99999,
}