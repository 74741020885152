import styled, { css } from 'styled-components'
import { colors } from '~/assets/styles/variables'

const {
  white, darkPurple, red
} = colors

export const errorMessage = css`
  background: ${white};
  color: ${darkPurple};
`

export const errorMessageProgress = css`
  background:${red};
`

export const ContainerMessage = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;
  }
`
