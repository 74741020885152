import React from "react";
import Lottie from "react-lottie";
import defaultOptions from "~/config/loaderAnimationSettings";
import { Container } from "./styles";

const Loader: React.FC = () => {
  return (
    <Container>
      <Lottie
        height={330}
        width={330}
        options={defaultOptions}
        isStopped={false}
        isPaused={false}
      />
    </Container>
  );
};

export default Loader;
