import styled from "styled-components";
import Border from "~/assets/images/border_background.png";
import { colors } from "~/assets/styles/variables";

const { darkBlue } = colors

interface DescriptionProps {
  small?: boolean;
}

export const RouletteContainer = styled.div`
  background: url(${Border}) no-repeat;
  background-size: cover;
  padding: 20px;

  @media screen and (max-height: 630px) and (orientation: landscape) {
    padding: 12px;

    & > div {
      max-width: 320px;
      max-height: 320px;
    }
  }
`;

export const Title = styled.h2`
  font-family: "Hind Madurai", sans-serif;
  font-size: 30px;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  font-family: "Hind Madurai", sans-serif;
  font-size: 25px;
  color: ${darkBlue};
  font-weight: bold;
`;

export const DescriptionVersion2 = styled.p<DescriptionProps>`
  font-family: "Hind Madurai", sans-serif;
  font-size: ${(props) => (props.small ? "12px" : "18px")};
  text-align: center;
  margin-top: 15px;
  color: ${darkBlue};
`;

export const ErrorMessage = styled.p`
  font-family: "Hind Madurai", sans-serif;
  font-size: 18px;
  text-align: center;
  margin-top: 5px;
`;
