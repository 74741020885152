import React, { useState, useEffect } from 'react';
import { Container, Box, Modal } from '@material-ui/core';
import { useParams } from 'react-router';
import { Wheel } from 'react-custom-roulette';

import Loader from '~/components/Loader';
import Button from '~/components/Button';
import Image from '~/components/Image';

import { colors } from '~/assets/styles/variables';

import * as PrizeHubServices from '~/services/PrizeHub';

import img from '~/assets/images/logoViacerta.svg';
import Logo from '~/assets/images/logo_viacerta.png';
import RodaDaSorte from '~/assets/images/roda_de_premios.png';

import ModalStyle from './boxStyle';
import { RouletteContainer, Description, DescriptionVersion2, ErrorMessage } from './styles';
import { AlertIcon } from '~/assets/icons';

interface AwardInterface {
  id: number,
  option: string
  style?: StyleType;
}

interface StyleType {
  backgroundColor?: string;
  textColor?: string;
}

const Roulette = () => {
  const { contractNumber, campaignSlug } = useParams();

  const [showLoader, setShowLoader] = useState<boolean>(true);
  const [mustSpin, setMustSpin] = useState<boolean>(false);
  const [prizeNumber, setPrizeNumber] = useState<number>(0);
  const [prizeDrawnMessage, setPrizeDrawnMessage] = useState<string>('');
  const [data, setData] = useState<AwardInterface[]>([]);
  const [isDisableButton, setIsDisableButton] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [showIconOrImgState, setShowIconOrImgState] = useState<boolean>(false);
  const [showErrorMessageState, setShowErrorMessageState] = useState<boolean>(false);
  const [errorMessageState, setErrorMessageState] = useState<string>('');

  const { blue, lightBlue, white, orange, ligthYellow } = colors;

  const handleClose = () => { !showErrorMessageState && setOpen(false) };

  useEffect(() => {
    if (!contractNumber || !campaignSlug) return
    PrizeHubServices.getPrizes(contractNumber, campaignSlug)
      .then((response) => {
        const prizeDescription = response.find((award) => !!award.isAwarded)?.description || '';

        const prizes: AwardInterface[] = response.slice(0, 8).filter((award) => !award.isAwarded)
          .map((item, index) => ({
            id: index + 1,
            option: item.description,
          }));

        const options = [{ id: 0, option: prizeDescription }, ...prizes];

        if (options.length % 2 !== 0) options.push({ id: 9, option: 'Não foi dessa vez' })

        setData(options);

        setPrizeDrawnMessage(prizeDescription);
        setShowLoader(false);
      }).catch((error) => {
        if (error.response.data.userMessage.includes('Por motivos de segurança')) {
          setErrorMessageState(error.response.data.userMessage)
          setShowIconOrImgState(true);
          setShowLoader(false);
          setShowErrorMessageState(true);
          setOpen(true);
        }
      })
  }, [contractNumber, campaignSlug]);

  const handleSpinClick = () => {
    const newPrizeNumber = 0;
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
  };

  const sendAward = () => {
    if (!contractNumber || !campaignSlug) return
    setIsDisableButton(true);
    PrizeHubServices.sendPrizeViewed(contractNumber, campaignSlug).then(() => {
    }).catch(() => {
      setIsDisableButton(false);
    })
  }

  const goBack = () => {
    window.history.back()
  }

  const setColorDraw = (data: AwardInterface[], positionDrawn: number) => {
    if (!data) return
    const styleDrawn: StyleType = { backgroundColor: orange, textColor: white }
    data.forEach(value => {
      if (value.id === positionDrawn) {
        value.style = styleDrawn
      }
    })
    setData(data)
  }

  return (
    <Container
      maxWidth="sm"
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center',
        padding: 0,
      }}
    >
      {showLoader ? (
        <Loader />
      ) : (
        <>
          <Image
            src={RodaDaSorte}
            width={'80%'}
            marginBottom={'20px'}
            margin={'20px'}
          />
          <RouletteContainer>
            <Wheel
              mustStartSpinning={mustSpin}
              prizeNumber={prizeNumber}
              data={data}
              outerBorderColor={lightBlue}
              outerBorderWidth={1}
              innerBorderColor={lightBlue}
              radiusLineWidth={0}
              textColors={[blue, white]}
              fontSize={18}
              backgroundColors={[white, blue]}
              onStopSpinning={() => {
                setColorDraw(data, data && data[prizeNumber].id);
                setMustSpin(false);
                setOpen(true);
                sendAward();
              }}

            />
          </RouletteContainer>

          <Button onClick={handleSpinClick} style={{ margin: '35px' }} disabled={isDisableButton}>
            Girar
          </Button>
          <Image src={Logo} height={30} onClick={() => goBack()} />

          <Modal open={open} onClose={handleClose}>
            <Box sx={ModalStyle}>
              <>
                {showIconOrImgState ?
                  <AlertIcon color={ligthYellow} iconHeight={'70px'} iconWidth={'70px'} /> :
                  <Image src={img} margin={'15px'} />
                }
                {showErrorMessageState && <ErrorMessage>{errorMessageState}</ErrorMessage>}
                {!showErrorMessageState && (
                  <>
                    <Description>Parabéns!</Description>
                    <DescriptionVersion2>Prêmio sorteado: {prizeDrawnMessage}</DescriptionVersion2>
                    <DescriptionVersion2 small>O Prêmio estará disponível para visualização no Sistema Acerta, o valor do prêmio será repassado pela sua loja.</DescriptionVersion2>
                  </>
                )}
              </>
            </Box>
          </Modal>
        </>
      )}
    </Container>
  );
};

export default Roulette;
