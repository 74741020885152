import styled from 'styled-components'

interface ImageInterface {
  margin?: string,
  marginBottom?: number | string,
  position?: string,
  width?: number | string,
  height?: number,
  maxWidth?: number | string,
  border?: number | string,
  borderRadius?: number | string,
}

const Image = styled.img`
 height: ${({height} : ImageInterface) => height};
 margin: ${({margin}: ImageInterface) => margin};
 margin-bottom: ${({marginBottom}: ImageInterface) => marginBottom};
 width: ${({width} : ImageInterface) => width};
 max-width: ${({maxWidth} : ImageInterface) => maxWidth};
 position: ${({position} : ImageInterface) => position};
 border: ${({border} : ImageInterface) => border};
 border-radius: ${({borderRadius} : ImageInterface) => borderRadius};
`
export default Image
