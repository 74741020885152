import api from '~/config/api';
import { PrizeStockInterface } from '~/models/interfaces/PrizeStock';

const pathDraw = '/v1/prize-draw'

const getPrizes = (contractNumber: string, campaignSlug: string) => api
  .get<PrizeStockInterface[]>(`${pathDraw}/queries/contract/${contractNumber}/commercial-campaign/${campaignSlug}/prize-stocks`)
  .then(response => response.data)

const sendPrizeViewed = (contractNumber: string, campaignSlug: string) => api
  .put(`${pathDraw}/contract/${contractNumber}/commercial-campaign/${campaignSlug}/viewed`)

export {
  getPrizes,
  sendPrizeViewed
}