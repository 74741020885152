import { createGlobalStyle } from 'styled-components';
import { zIndex } from './variables';
import {
  errorMessage,
  errorMessageProgress,
} from '~/components/Toast/Toast.styled';

import styled from 'styled-components';

const { fifthLayer } = zIndex;

export default createGlobalStyle`
    * {
        margin: 0;;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    body {
        height: 94vh;
        width: 100%;
        background: radial-gradient(circle,#002546 0,#2287d4 0,#002546 100%);
        -webkit-font-smoothing: antialiased;
    }

    #root {
        flex-direction: column;
        padding: 0px;
    }

    button {
        cursor: pointer;
        width: 205px;
        height: 52px;
        background: #FF8500;
        border-radius: 5px;
        border: 0;
        color: #fff;
        font-weight: bold;
        transition: background-color 0.2s;
        }

    input {
        flex: 1;
        height: 52px;
        width: 205px;
        padding: 0 24px;
        border: 0;
        border-radius: 5px;
        color: #3a3a3a;

    &::placeholder {
      color: #a8a8b3;
    }
    }

    h1, a {
        color: #fff;
        text-decoration: none;
    }

    h1 {
        font-family: 'Hind Madurai', sans-serif;
        font-size: 30px;
        text-transform: capitalize;
        margin-top: 20px;
    }

    form {
        margin: 50px 0px;
    }

    .Toastify__toast-container {
  z-index: ${fifthLayer} !important;
    }

  .Toastify__toast--error {
  ${errorMessage}
}

.Toastify__progress-bar--error {
  ${errorMessageProgress}
}

`;

export const LottieContainer = styled.div`
  position: fixed;
  top: 50px;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Image = styled.img`
  height: 30px;
`;
